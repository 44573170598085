import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout, PostCard } from '../components/common'
import { MetaData } from '../components/common/meta'

import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import { Tags } from '@tryghost/helpers-gatsby'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost
    const relatedPosts = data.allGhostPost.edges
    const readingTime = readingTimeHelper(post)

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        <header className="post-full-content">
                            <div className="content-meta">
                                <span className="content-date">
                                    {post.published_at_pretty}
                                </span>
                                <span>
                                    {readingTime}
                                </span>
                                <span>
                                    {post.tags && <Tags post={post} visibility="public" autolink={true} permalink="/tag/:slug" />}
                                </span>
                            </div>
                            <h1 className="content-title">{post.title}</h1>
                        </header>
                        <section className="post-full-content">

                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                    { relatedPosts.length > 0 ?
                        <section className="post-full-content">
                            <h3>Related posts</h3>
                            <section className="post-feed">
                                {relatedPosts.map(({ node }) => (
                                    // The tag below includes the markup for each post - components/common/PostCard.js
                                    <PostCard key={node.id} post={node} />
                                ))}
                            </section>
                        </section> : null }
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!, $tag: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {slug: { ne: $slug }, tags: {elemMatch: {slug: {eq: $tag}}}},
            limit: 5,
            skip: 0
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
